import { MutationTree } from "vuex";
import { RootState } from "./state";
import { AgentModel, DataSourceModel, TagModel, UserModel } from "../types";
import { RoomModel } from "@/types/room";
import { SessionModel } from "@/types/session";
import { ChatModel } from "@/types/chat";
import _ from "lodash";
import { RoleModel } from "@/types/role";
import { DashboardModel } from "@/types/dashboard";
import { removeWhiteSpace } from "../utils/FuncUtils";
import { FeedbackModel } from "@/types/feedback";

export enum MutationTypes {
  // DASHBOARD
  SET_DASHBOARD = "SET_DASHBOARD",
  // USERS
  ADD_USER = "ADD_USER",
  SET_USER = "SET_USER",
  SET_USER_LIST = "SET_USER_LIST",
  SET_IMAGE_PROFILE = "SET_IMAGE_PROFILE",
  SET_USER_ROLE = "SET_USER_ROLE",
  UPDATE_USER = "UPDATE_USER",
  // AGENTS
  ADD_AGENT = "ADD_AGENT",
  SET_AGENT = "SET_AGENT",
  UPDATE_AGENT = "UPDATE_AGENT",
  DELETE_AGENT = "DELETE_AGENT",
  // ROOMS
  SET_ROOM = "SET_ROOM",
  // SESSIONS
  SET_SESSION = "SET_SESSION",
  CREATE_SESSION = "CREATE_SESSION",
  UPDATE_SESSION = "UPDATE_SESSION",
  // CHATS
  SET_CHAT = "SET_CHAT",
  UPDATE_CHAT = "UPDATE_CHAT",
  DUMMY_CHAT = "DUMMAY_CHAT",
  DELETE_DUMMY_CHAT = "DELETE_DUMMY_CHAT",
  EMPTY_CHAT = "EMPTY_CHAT",
  // TAGS
  SET_TAG = "SET_TAG",
  CREATE_TAG = "CREATE_TAG",
  UPDATE_TAG = "UPDATE_TAG",
  SET_SELECTED_TAG = "SET_SELECTED_TAG",
  // AGENT TAGS
  UPDATE_AGENT_TAG = "UPDATE_AGENT_TAG",
  // ROLES
  CREATE_ROLE = "CREATE_ROLE",
  SET_ROLE = "SET_ROLE",
  UPDATE_ROLE = "UPDATE_ROLE",
  DELETE_ROLE = "DELETE_ROLE",
  // ROLE AGENTS
  UPDATE_ROLE_AGENT = "UPDATE_ROLE_AGENT",
  // ROLE USER
  UPDATE_ROLE_USER = "UPDATE_ROLE_USER",
  // SESSIONS
  DELETE_SESSION = "DELETE_SESSION",
  // FEEDBACKS
  SET_FEEDBACK = "SET_FEEDBACK",
  SET_FEEDBACK_TO_CHAT = "SET_FEEDBACK_TO_CHAT",
  // UTILS
  SET_SEARCH = "SET_SEARCH",
  SET_LOADING = "SET_LOADING",
  SET_BLOCKING_INPUT = "SET_BLOCKING_INPUT",
  SET_COMMENT_SAVING = "SET_COMMENT_SAVING",
}

export type Mutations = {
  // DASHBOARD
  [MutationTypes.SET_DASHBOARD](
    state: RootState,
    dashboard: DashboardModel
  ): void;
  // USERS
  [MutationTypes.ADD_USER](state: RootState, user: UserModel): void;
  [MutationTypes.SET_USER](state: RootState, user: UserModel): void;
  [MutationTypes.SET_IMAGE_PROFILE](
    state: RootState,
    imageProfile: string
  ): void;
  [MutationTypes.SET_USER_ROLE](
    state: RootState,
    payload: {
      roleId: number;
      roleName: string;
      username: string;
    }
  ): void;
  [MutationTypes.UPDATE_USER](state: RootState, user: UserModel): void;
  // AGENTS
  [MutationTypes.ADD_AGENT](state: RootState, agent: AgentModel): void;
  [MutationTypes.SET_AGENT](state: RootState, agent: AgentModel[]): void;
  [MutationTypes.UPDATE_AGENT](state: RootState, agent: AgentModel): void;
  [MutationTypes.DELETE_AGENT](state: RootState, id: number): void;
  // ROOMS
  [MutationTypes.SET_ROOM](state: RootState, room: RoomModel): void;
  // SESSIONS
  [MutationTypes.SET_SESSION](state: RootState, session: SessionModel[]): void;
  [MutationTypes.CREATE_SESSION](state: RootState, session: SessionModel): void;
  [MutationTypes.UPDATE_SESSION](state: RootState, session: SessionModel): void;
  [MutationTypes.DELETE_SESSION](state: RootState, sessionId: number): void;
  // CHATS
  [MutationTypes.SET_CHAT](state: RootState, chat: ChatModel[]): void;
  [MutationTypes.UPDATE_CHAT](state: RootState, payload: any): void;
  [MutationTypes.DUMMY_CHAT](state: RootState, chat: ChatModel): void;
  [MutationTypes.DELETE_DUMMY_CHAT](state: RootState): void;
  [MutationTypes.EMPTY_CHAT](state: RootState): void;
  // TAGS
  [MutationTypes.SET_TAG](state: RootState, tag: TagModel[]): void;
  [MutationTypes.UPDATE_TAG](state: RootState, tag: TagModel): void;
  [MutationTypes.SET_SELECTED_TAG](state: RootState, tag: string[]): void;
  // AGENT TAGS
  [MutationTypes.UPDATE_AGENT_TAG](
    state: RootState,
    payload: {
      agentId: number;
      tagName: string;
      isAdd: boolean;
    }
  ): void;
  // ROLES
  [MutationTypes.CREATE_ROLE](state: RootState, role: RoleModel): void;
  [MutationTypes.SET_ROLE](state: RootState, role: RoleModel[]): void;
  [MutationTypes.UPDATE_ROLE](state: RootState, role: RoleModel): void;
  [MutationTypes.DELETE_ROLE](state: RootState, id: number): void;
  // ROLE AGENTS
  [MutationTypes.UPDATE_ROLE_AGENT](
    state: RootState,
    payload: {
      roleId: number;
      agentName: string;
      isAdd: boolean;
    }
  ): void;
  // ROLE USER
  [MutationTypes.UPDATE_ROLE_USER](
    state: RootState,
    payload: {
      roleName: string;
      username: string;
      isAdd: boolean;
    }
  ): void;
  // FEEDBACKS
  [MutationTypes.SET_FEEDBACK](
    state: RootState,
    feedback: FeedbackModel[]
  ): void;
  [MutationTypes.SET_FEEDBACK_TO_CHAT](state: RootState, payload: any): void;
  // UTILS
  [MutationTypes.SET_SEARCH](state: RootState, search: string): void;
  [MutationTypes.SET_LOADING](state: RootState, isLoading: boolean): void;
  [MutationTypes.SET_BLOCKING_INPUT](
    state: RootState,
    isBloacking: boolean
  ): void;
  [MutationTypes.SET_COMMENT_SAVING](state: RootState, payload: any): void;
};

export const mutations: MutationTree<RootState> & Mutations = {
  // DASHBOARD
  [MutationTypes.SET_DASHBOARD](state, dashboard) {
    state.dashboard = dashboard;
  },
  // USERS
  [MutationTypes.ADD_USER](state, user) {
    state.userList.push(user);
  },
  [MutationTypes.SET_USER](state, user) {
    state.user = user;
    if (user.imageProfile?.Valid && user.imageProfile?.String) {
      state.user.localImageProfile = user.imageProfile?.String;
    }
  },
  [MutationTypes.SET_USER_LIST](state, userList) {
    state.userList = userList;
  },
  [MutationTypes.SET_IMAGE_PROFILE](state, imageProfile) {
    state.user.localImageProfile = imageProfile;
  },
  [MutationTypes.SET_USER_ROLE](state, payload) {
    const userList = [...state.userList];
    const index = userList.findIndex(
      (item) => item.username === payload.username
    );
    if (index !== -1) {
      userList[index].roleId = {
        String: payload.roleId,
        Valid: true,
      };
      userList[index].roleName = {
        String: payload.roleName,
        Valid: true,
      };
      state.userList = userList;
    }
  },
  [MutationTypes.UPDATE_USER](state, user) {
    const userList = [...state.userList];
    const indexToRemove = userList.findIndex(
      (item) => item.username === user.username
    );
    if (indexToRemove !== -1) {
      userList.splice(indexToRemove, 1, user);
      state.userList = userList;
    }
  },
  // AGENTS
  [MutationTypes.ADD_AGENT](state, agent) {
    state.agent.push(agent);
  },
  [MutationTypes.SET_AGENT](state, agent) {
    try {
      for (const item of agent as AgentModel[]) {
        if (item.dataSource?.Valid && item.dataSource.String) {
          const jsonArray: DataSourceModel[] = JSON.parse(
            item.dataSource.String
          );
          item.source = jsonArray;
        }
      }
    } catch (error) {
      console.error(error);
    }
    state.agent = [...agent];
  },
  [MutationTypes.UPDATE_AGENT](state, agent) {
    const agentList = [...state.agent];
    const indexToRemove = agentList.findIndex((item) => item.id === agent.id);
    if (indexToRemove !== -1) {
      agentList.splice(indexToRemove, 1, agent);
      state.agent = agentList;
    }
  },
  [MutationTypes.DELETE_AGENT](state, id) {
    const agentList = [...state.agent];
    const indexToRemove = agentList.findIndex((item) => item.id === id);
    if (indexToRemove !== -1) {
      agentList.splice(indexToRemove, 1);
      state.agent = agentList;
    }
  },
  // ROOMS
  [MutationTypes.SET_ROOM](state, room) {
    state.room = room;
  },
  // SESSIONS
  [MutationTypes.SET_SESSION](state, session) {
    state.session = [...session];
  },
  [MutationTypes.CREATE_SESSION](state, session) {
    state.session = [...state.session, session];
  },
  [MutationTypes.UPDATE_SESSION](state, session) {
    const sessionList = [...state.session];

    const indexToRemove = sessionList.findIndex(
      (item) => item.id === session.id
    );
    if (indexToRemove !== -1) {
      sessionList.splice(indexToRemove, 1, session);
      state.session = sessionList;
    }
  },
  [MutationTypes.DELETE_SESSION](state, sessionId) {
    const sessionList = [...state.session];

    const indexToRemove = sessionList.findIndex(
      (item) => item.id === sessionId
    );
    if (indexToRemove !== -1) {
      sessionList.splice(indexToRemove, 1);
      state.session = sessionList;
    }
  },
  // FEEDBACKS
  [MutationTypes.SET_FEEDBACK](state, feedback) {
    state.feedback = [...feedback];
  },
  [MutationTypes.SET_FEEDBACK_TO_CHAT](state, payload) {
    const chatList = [...state.chat];

    const index = chatList.findIndex(
      (item) => item.id === payload.chatHistoryId
    );
    if (index !== -1) {
      if (payload.isLike) chatList[index].isLike = payload.isLike;
      if (payload.star) chatList[index].star = payload.star;
      if (payload.comment) chatList[index].comment = payload.comment;
      state.chat = chatList;
    }
  },
  // CHATS
  [MutationTypes.SET_CHAT](state, chat) {
    state.chat = [...chat];
  },
  [MutationTypes.UPDATE_CHAT](state, payload) {
    const chatList = [...state.chat];

    const indexToRemove = chatList.findIndex(
      (item) => item.id === payload.dummyId
    );
    if (indexToRemove !== -1) {
      chatList.splice(indexToRemove, 1, payload.chat);
      state.chat = chatList;
    } else {
      state.chat = [...chatList, payload.chat];
    }
  },
  [MutationTypes.DUMMY_CHAT](state, chat) {
    const chatList = [...state.chat];

    const indexToRemove = chatList.findIndex((item) => item.id === chat.id);
    if (indexToRemove !== -1) {
      chatList.splice(indexToRemove, 1, chat);
      state.chat = chatList;
    } else {
      state.chat = [...chatList, chat];
    }
  },
  [MutationTypes.DELETE_DUMMY_CHAT](state) {
    const chatList = [...state.chat];

    const indexToRemove = chatList.findIndex((item) => item.id === 999);
    if (indexToRemove !== -1) {
      chatList.splice(indexToRemove, 1);
      state.chat = chatList;
    }
  },
  [MutationTypes.EMPTY_CHAT](state) {
    state.chat = [];
  },
  // TAGS
  [MutationTypes.SET_TAG](state, tag) {
    state.tag = [...tag];
  },
  [MutationTypes.CREATE_TAG](state, tag) {
    state.tag = [...state.tag, tag];
  },
  [MutationTypes.UPDATE_TAG](state, tag) {
    const tagList = [...state.tag];

    const indexToRemove = tagList.findIndex((item) => item.id === tag.id);
    if (indexToRemove !== -1) {
      tagList.splice(indexToRemove, 1, tag);
      state.tag = tagList;
    }
  },
  [MutationTypes.SET_SELECTED_TAG](state, tag) {
    state.selectedTag = [...tag];
  },
  // AGENT TAGS
  [MutationTypes.UPDATE_AGENT_TAG](state, payload) {
    const agentList = [...state.agent];

    const index = agentList.findIndex((item) => item.id === payload.agentId);
    if (index !== -1) {
      const agent = agentList[index];
      if (payload.isAdd) {
        let tagList = agent.tagName?.split(",").filter((item) => item !== "");
        if (tagList) {
          tagList.push(removeWhiteSpace(payload.tagName));
          agent.tagName = tagList.join(",");
        } else {
          tagList = [];
          tagList.push(removeWhiteSpace(payload.tagName));
          agent.tagName = tagList.join(",");
        }
      } else {
        const tagList = agent.tagName?.split(",").filter((item) => item !== "");
        if (tagList) {
          const indexToRemove = tagList.findIndex(
            (item) =>
              removeWhiteSpace(item) === removeWhiteSpace(payload.tagName)
          );
          if (indexToRemove !== -1) {
            tagList.splice(indexToRemove, 1);
            agent.tagName = tagList.join(",");
          }
        }
      }
      state.agent = agentList;
    }
  },
  // ROLES
  [MutationTypes.CREATE_ROLE](state, role) {
    state.role = [...state.role, role];
  },
  [MutationTypes.SET_ROLE](state, role) {
    state.role = [...role];
  },
  [MutationTypes.UPDATE_ROLE](state, role) {
    const roleList = [...state.role];

    const indexToRemove = roleList.findIndex((item) => item.id === role.id);
    if (indexToRemove !== -1) {
      roleList.splice(indexToRemove, 1, role);
      state.role = roleList;
    }
  },
  [MutationTypes.DELETE_ROLE](state, id) {
    const roleList = [...state.role];

    const indexToRemove = roleList.findIndex((item) => item.id === id);
    if (indexToRemove !== -1) {
      roleList.splice(indexToRemove, 1);
      state.role = roleList;
    }
  },
  // ROLE AGENTS
  [MutationTypes.UPDATE_ROLE_AGENT](state, payload) {
    const roleList = [...state.role];

    const index = roleList.findIndex((item) => item.id === payload.roleId);
    if (index !== -1) {
      const role = roleList[index];
      if (payload.isAdd) {
        let agentList = role.agentName?.String.split(",").filter(
          (item) => item !== ""
        );
        if (agentList) {
          agentList.push(removeWhiteSpace(payload.agentName));
          role.agentName = { String: agentList.join(","), Valid: true };
        } else {
          agentList = [];
          agentList.push(removeWhiteSpace(payload.agentName));
          role.agentName = { String: agentList.join(","), Valid: true };
        }
      } else {
        const agentList = role.agentName?.String.split(",").filter(
          (item) => item !== ""
        );
        if (agentList) {
          const indexToRemove = agentList.findIndex(
            (item) =>
              removeWhiteSpace(item) === removeWhiteSpace(payload.agentName)
          );
          if (indexToRemove !== -1) {
            agentList.splice(indexToRemove, 1);
            role.agentName = { String: agentList.join(","), Valid: true };
          }
        }
      }
      state.role = roleList;
    }
  },
  // ROLE USER
  [MutationTypes.UPDATE_ROLE_USER](state, payload) {
    const userList = [...state.userList];

    const index = userList.findIndex(
      (item) => item.username === payload.username
    );

    if (index !== -1) {
      const user = userList[index];
      if (payload.isAdd) {
        let roleList = user.roleName?.String.split(",").filter(
          (item) => item !== ""
        );
        if (roleList) {
          roleList.push(removeWhiteSpace(payload.roleName));
          user.roleName = { String: roleList.join(","), Valid: true };
        } else {
          roleList = [];
          roleList.push(removeWhiteSpace(payload.roleName));
          user.roleName = { String: roleList.join(","), Valid: true };
        }
      } else {
        const roleList = user.roleName?.String.split(",").filter(
          (item) => item !== ""
        );

        if (roleList) {
          const indexToRemove = roleList.findIndex(
            (item) =>
              removeWhiteSpace(item) === removeWhiteSpace(payload.roleName)
          );
          if (indexToRemove !== -1) {
            roleList.splice(indexToRemove, 1);
            user.roleName = { String: roleList.join(","), Valid: true };
          }
        }
      }
      userList[index] = user;
      state.userList = userList;
    }
  },
  // UTILS
  [MutationTypes.SET_SEARCH](state, search) {
    state.search = search;
  },
  [MutationTypes.SET_LOADING](state, isLoading) {
    state.isLoading = isLoading;
  },
  [MutationTypes.SET_BLOCKING_INPUT](state, isBloacking) {
    state.isBlockingInput = isBloacking;
  },
  [MutationTypes.SET_COMMENT_SAVING](state, payload) {
    const chatList = [...state.chat];

    const index = chatList.findIndex(
      (item) => item.id === payload.chatHistoryId
    );
    if (index !== -1) {
      chatList[index].isShowSaving = payload.isShowSaving;
      chatList[index].comment = payload.comment;
      state.chat = chatList;
    }
  },
};
